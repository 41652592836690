var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_vm._m(0),_c('section',{staticClass:"tour-suggest list-order"},[_vm._m(1),_c('div',{staticClass:"ranking"},[_c('div',{staticClass:"rank-highest"},[_c('div',{staticClass:"rank-highest_box"},[_c('div',{staticClass:"rank-highest_items second-rank"},[_vm._m(2),_vm._m(3),_c('p',{staticClass:"rank-highest-items_name"},[_vm._v(_vm._s(_vm.users[1]?.name))]),_c('p',{staticClass:"rank-highest-items_count"},[_vm._v(_vm._s(_vm.users[1]?.invited_turn)+" lượt")])]),_c('div',{staticClass:"rank-highest_items"},[_vm._m(4),_vm._m(5),_c('p',{staticClass:"rank-highest-items_name"},[_vm._v(_vm._s(_vm.users[0]?.name))]),_c('p',{staticClass:"rank-highest-items_count"},[_vm._v(_vm._s(_vm.users[0]?.invited_turn)+" lượt")])]),_c('div',{staticClass:"rank-highest_items second-rank"},[_vm._m(6),_vm._m(7),_c('p',{staticClass:"rank-highest-items_name"},[_vm._v(_vm._s(_vm.users[2]?.name))]),_c('p',{staticClass:"rank-highest-items_count"},[_vm._v(_vm._s(_vm.users[2]?.invited_turn)+" lượt")])])])]),_c('div',{staticClass:"rank-normal"},_vm._l((_vm.users),function(user,index){return _c('div',{key:index,staticClass:"rank-normal_box"},[(index > 2)?_c('div',{staticClass:"rank-normal_items"},[_c('div',{staticClass:"flex items-center"},[_c('p',{staticClass:"rank-normal_number"},[_vm._v(_vm._s(index < 9 ? 0 : '')+_vm._s(index + 1))]),_c('div',{staticClass:"flex items-center gap-x-2"},[_vm._m(8,true),_c('p',[_vm._v(_vm._s(user.name))])])]),_c('p',{staticClass:"rank-highest-items_count"},[_vm._v(_vm._s(user.invited_turn)+" lượt")])]):_vm._e()])}),0)])]),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header-fixed"},[_c('div',{staticClass:"header-main"},[_c('div',{staticClass:"header-logo"},[_c('div',{staticClass:"header-logo_img"},[_c('img',{attrs:{"src":require("@/assets/img/paw-logo.png"),"alt":""}})]),_c('div',{staticClass:"header-logo_title"},[_vm._v("CAT MONEY")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-between items-center relative"},[_c('div',{staticClass:"title-primary text-white text-title"},[_vm._v(" Xếp hạng lượt giới thiệu ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rank-highest-medal_img"},[_c('img',{attrs:{"src":require("@/assets/img/silver-medal.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rank-img"},[_c('div',{staticClass:"rank-highest-avatar_img"},[_c('img',{attrs:{"src":require("@/assets/img/paw-logo.png"),"alt":""}})]),_c('div',{staticClass:"rank-highest-avatar_checked"},[_c('img',{attrs:{"src":require("@/assets/img/checked.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rank-highest-medal_img"},[_c('img',{attrs:{"src":require("@/assets/img/gold-medal.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rank-img"},[_c('div',{staticClass:"rank-highest-avatar_img"},[_c('img',{attrs:{"src":require("@/assets/img/paw-logo.png"),"alt":""}})]),_c('div',{staticClass:"rank-highest-avatar_checked"},[_c('img',{attrs:{"src":require("@/assets/img/checked.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rank-highest-medal_img"},[_c('img',{attrs:{"src":require("@/assets/img/bronze-medal.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rank-img"},[_c('div',{staticClass:"rank-highest-avatar_img"},[_c('img',{attrs:{"src":require("@/assets/img/paw-logo.png"),"alt":""}})]),_c('div',{staticClass:"rank-highest-avatar_checked"},[_c('img',{attrs:{"src":require("@/assets/img/checked.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rank-normal-img"},[_c('div',{staticClass:"rank-normal-items_img"},[_c('img',{attrs:{"src":require("@/assets/img/paw-logo.png"),"alt":""}})]),_c('div',{staticClass:"rank-normal-avatar_checked"},[_c('img',{attrs:{"src":require("@/assets/img/checked.png"),"alt":""}})])])
}]

export { render, staticRenderFns }