<template>
  <div class="wrapper">
    <header class="header-fixed">
      <div class="header-main">
        <div class="header-logo">
          <div class="header-logo_img">
            <img src="@/assets/img/paw-logo.png" alt="">
          </div>
          <div class="header-logo_title">CAT MONEY</div>
        </div>
      </div>
    </header>

    <section class="tour-suggest list-order">
      <div class="flex justify-between items-center relative">
        <div class="title-primary text-white text-title">
          Xếp hạng lượt giới thiệu
        </div>
      </div>

      <div class="ranking">
        <div class="rank-highest">
          <div class="rank-highest_box">
            <div class="rank-highest_items second-rank">
              <div class="rank-highest-medal_img">
                <img src="@/assets/img/silver-medal.png" alt="">
              </div>
              <div class="rank-img">
                <div class="rank-highest-avatar_img">
            <img src="@/assets/img/paw-logo.png" alt="">
                  
                </div>
                <div class="rank-highest-avatar_checked">
                  <img src="@/assets/img/checked.png" alt="">
                </div>
              </div>

              <p class="rank-highest-items_name">{{ users[1]?.name }}</p>
              <p class="rank-highest-items_count">{{ users[1]?.invited_turn }} lượt</p>
            </div>
            <div class="rank-highest_items">
              <div class="rank-highest-medal_img">
                <img src="@/assets/img/gold-medal.png" alt="">
              </div>
              <div class="rank-img">
                <div class="rank-highest-avatar_img">
            <img src="@/assets/img/paw-logo.png" alt="">
                  
                </div>
                <div class="rank-highest-avatar_checked">
                  <img src="@/assets/img/checked.png" alt="">
                </div>
              </div>
              <p class="rank-highest-items_name">{{ users[0]?.name }}</p>
              <p class="rank-highest-items_count">{{users[0]?.invited_turn}} lượt</p>
            </div>
            <div class="rank-highest_items second-rank">
              <div class="rank-highest-medal_img">
                <img src="@/assets/img/bronze-medal.png" alt="">
              </div>
              <div class="rank-img">
                <div class="rank-highest-avatar_img">
            <img src="@/assets/img/paw-logo.png" alt="">
                  
                </div>
                <div class="rank-highest-avatar_checked">
                  <img src="@/assets/img/checked.png" alt="">
                </div>
              </div>
              <p class="rank-highest-items_name">{{ users[2]?.name }}</p>
              <p class="rank-highest-items_count">{{users[2]?.invited_turn}} lượt</p>
            </div>
          </div>
        </div>

        <div class="rank-normal">
          <div class="rank-normal_box" v-for="(user, index) in users" :key="index">

            <div class="rank-normal_items" v-if="index > 2">
              <div class="flex items-center">
                <p class="rank-normal_number">{{ index < 9 ? 0 : '' }}{{index + 1}}</p>
                <div class="flex items-center gap-x-2">
                  <div class="rank-normal-img">
                    <div class="rank-normal-items_img">
            <img src="@/assets/img/paw-logo.png" alt="">
                      
                    </div>
                    <div class="rank-normal-avatar_checked">
                      <img src="@/assets/img/checked.png" alt="">
                    </div>
                  </div>
                  <p>{{ user.name }}</p>
                </div>
              </div>
              <p class="rank-highest-items_count">{{ user.invited_turn }} lượt</p>
            </div>

            <!-- <div class="rank-normal_items">
              <div class="flex items-center">
                <p class="rank-normal_number">5</p>
                <div class="flex items-center gap-x-2">
                  <div class="rank-normal-img">
                    <div class="rank-normal-items_img">
            <img src="@/assets/img/paw-logo.png" alt="">
                      
                    </div>
                    <div class="rank-normal-avatar_checked">
                      <img src="@/assets/img/checked.png" alt="">
                    </div>
                  </div>
                  <p>Nguyễn Văn E</p>
                </div>
              </div>
              <p class="rank-highest-items_count">6 lượt</p>
            </div>

            <div class="rank-normal_items">
              <div class="flex items-center">
                <p class="rank-normal_number">6</p>
                <div class="flex items-center gap-x-2">
                  <div class="rank-normal-img">
                    <div class="rank-normal-items_img">
            <img src="@/assets/img/paw-logo.png" alt="">
                      
                    </div>
                    <div class="rank-normal-avatar_checked">
                      <img src="@/assets/img/checked.png" alt="">
                    </div>
                  </div>
                  <p>Nguyễn Văn F</p>
                </div>
              </div>
              <p class="rank-highest-items_count">3 lượt</p>
            </div>

            <div class="rank-normal_items">
              <div class="flex items-center">
                <p class="rank-normal_number">6</p>
                <div class="flex items-center gap-x-2">
                  <div class="rank-normal-img">
                    <div class="rank-normal-items_img">
            <img src="@/assets/img/paw-logo.png" alt="">
                      
                    </div>
                    <div class="rank-normal-avatar_checked">
                      <img src="@/assets/img/checked.png" alt="">
                    </div>
                  </div>
                  <p>Nguyễn Văn G</p>
                </div>
              </div>
              <p class="rank-highest-items_count">1 lượt</p>
            </div> -->
          </div>
        </div>
      </div>

    </section>

    <Footer />
  </div>
</template>

<script>
import Footer from '@/views/user/components/Footer'
import network from '@/core/network'
export default {
  components: {
    Footer
  },
  data() {
    return {
      users: {}
    }
  },
  mounted() {
    this.getUserRank();
  },
  methods: {
    getUserRank() {
        network.get('/users/get_user_rank').then( res => {
          this.users = res.data.data.docs;
        }).catch(err => {
          console.log(err);
        })
      },
    formatDate(timestamp) {
      const date = new Date(timestamp);
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    },
    formatMoney(number) {

      return parseInt(number).toLocaleString('vi-VN', {
        useGrouping: true,
        minimumFractionDigits: 0
      }).replace(/\./g, ',');
    }
  }

}
</script>

<style></style>